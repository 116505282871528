var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.extendedSearchFilterFields.length ||
    _vm.activeSearchFilters.length ||
    _vm.pagedSearchTopics
  )?_c('v-row',[(_vm.extendedSearchFilterFields.length)?[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"4"}},[_c('SearchFilterFacetPanels')],1):_vm._e()]:_vm._e(),(_vm.activeSearchFilters.length || _vm.pagedSearchTopics)?[_c('v-col',{class:{
        'pa-0 d-flex flex-column': true,
        'pt-2 pb-5 pl-6 pr-8': _vm.$vuetify.breakpoint.mdAndUp,
      },attrs:{"cols":"12","md":"8"}},[(_vm.pagedSearchTopics)?[_c('SearchFilter',{staticClass:"flex-grow-0"})]:_vm._e(),(_vm.activeSearchFilters.length)?[_c('SearchFilterChips',{staticClass:"flex-grow-0"})]:_vm._e(),(_vm.pagedSearchTopics)?[_c('SearchTopicPage',{staticClass:"flex-grow-1"}),_c('SearchTopicPageNavigation',{staticClass:"flex-grow-0"})]:_vm._e()],2)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }