
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import SearchFilterDialog from "./SearchFilterDialog.vue";
import SearchHelpPopup from "@/components/search/SearchHelpPopup.vue";

@Component({
  components: {
    SearchHelpPopup,
    SearchFilterDialog,
  },
  computed: {
    ...mapGetters(["searchQuery", "pagedSearchTopics"]),
  },
})
export default class SearchFilter extends Vue {}
