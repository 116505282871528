
import { ISearchTopic } from "@/interfaces/api/v4/search-topic.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import { pluck } from "@/utils/resource";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["mdiAlertOutline"]),
  },
})
export default class SearchTopic extends Vue {
  @Prop({ default: null }) topic!: ISearchTopic;

  get sourceId(): string {
    return this?.topic?.source_id || "";
  }

  get legalName(): string {
    const legalName = pluck(this?.topic?.names, "entity_name");
    return legalName?.text || "";
  }

  get entityType(): string {
    const entityType = pluck(this?.topic?.attributes, "entity_type");
    return entityType?.value || "";
  }

  get entityStatus(): string {
    const entityType = pluck(this?.topic?.attributes, "entity_status");
    return entityType?.value || "";
  }

  get craBusinessNumber(): string {
    const craBusinessNumber = pluck(this?.topic?.names, "business_number");
    return craBusinessNumber?.text || "";
  }
}
